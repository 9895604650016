export const GET_LOCATION_BEGIN = "GET_LOCATION_BEGIN";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";

export const GET_PLACE_BEGIN = "GET_PLACE_BEGIN";
export const GET_PLACE_SUCCESS = "GET_PLACE_SUCCESS";
export const GET_PLACE_ERROR = "GET_PLACE_ERROR";

export const GET_SINGLE_PRODUCT_BEGIN = "GET_SINGLE_PRODUCT_BEGIN";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_ERROR = "GET_SINGLE_PRODUCT_ERROR";
