import images from "./images";

const imageHero = [
  // {
  //   imageUrl: images.prabhuhomeheroo1,
  // },
  // {
  //   imageUrl: images.sasanimg15,
  // },
  // {
  //   imageUrl: images.prabhuimg12,
  // },
  // {
  //   imageUrl: images.prabhuimg9,
  // },
  // {
  //   imageUrl: images.sasanimg13,
  // },
  { imageUrl: images.kk8 },
  { imageUrl: images.kk1 },
  { imageUrl: images.kkr1 },
  { imageUrl: images.kkr2 },
  { imageUrl: images.kk9 },
  { imageUrl: images.kk6 },
  { imageUrl: images.kk7 },
  { imageUrl: images.kk10 },
];

const imageH = [
  { id: 1, imageUrl: images.heroimg1 },
  { id: 2, imageUrl: images.heroimg2 },
  { id: 3, imageUrl: images.heroimg3 },
  { id: 4, imageUrl: images.heroimg4 },
  { id: 5, imageUrl: images.heroimg5 },
];

const imageEvent = [
  { id: 1, imageUrl: images.wed1 },
  { id: 2, imageUrl: images.wed11 },
  { id: 3, imageUrl: images.wed2 },
  { id: 4, imageUrl: images.wed9 },
  { id: 5, imageUrl: images.wed12 },
];
const imageDinning = [
  { id: 1, imageUrl: images.homegallery1 },
  { id: 2, imageUrl: images.homegallery2 },
  { id: 3, imageUrl: images.homegallery3 },
  { id: 4, imageUrl: images.homegallery4 },
  { id: 5, imageUrl: images.homegallery5 },
];
const imageBanner = [
  { imageUrl: images.heroimg1 },
  { imageUrl: images.heroimg2 },
  { imageUrl: images.heroimg3 },
  { imageUrl: images.heroimg4 },
  { imageUrl: images.heroimg5 },
];
export default { imageHero, imageH, imageEvent, imageDinning, imageBanner };
